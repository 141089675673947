<!--
 * @Description: 品质管理-检查表模板
 * @Author: 马俊
 * @Date: 2021-04-27 15:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="qualityChecklistTemplateList-wrapper">
    <list
      ref="list"
      :exportPermission="'export'"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :exportMethod="exportMethod"
      :searchParams="searchParams"
      :isMultiSelect="false"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>

      <template #searchSlot>
        <v-input label="检查表模板名称" placeholder="检查表模板名称" v-model="searchParams.name" />
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"/>
        <v-datepicker
          type="rangedatetimer"
          label="创建时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.createStartTime"
          :endTime.sync="searchParams.createEndTime"
        />
      </template>
      <template #operateSlot="scope">
        <v-button v-if="isShowEditBtn(scope.row)" text="编辑" type="text" permission="edit" @click="edit(scope.row)" />
        <v-button :text="scope.row.status === 0 ? '停用' : '恢复'"
          type="text" permission="status" @click="updateStatus(scope.row)"
        />
      </template>
    </list>
  </div>
</template>

<script>
import {
  getQualityCheckTemplateListURL
} from './api'
import {
  statusMap,
  statusOps
} from './map'
// import { communityParams, regionParams } from 'common/select2Params'
// import { mapHelper } from 'common/utils'

export default {
  name: 'qualityChecklistTemplateList',
  data () {
    return {
      searchUrl: getQualityCheckTemplateListURL,
      exportUrl: 'xx',
      exportMethod: 'get',
      searchParams: {
        // assetCategoryOneId: undefined,
        // assetCategoryTwoId: undefined,
        // assetCategoryThreeId: undefined,
        name: '',
        status: undefined,
        createStartTime: '',
        createEndTime: ''
      },
      headers: [
        {
          prop: 'name',
          label: '检查表模板名称'
        },
        {
          prop: 'itemCount',
          label: '检查项'
        },
        {
          prop: 'status',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'createUserName',
          label: '创建人'
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }
      ],
      // regionParams,
      // communityParams,
      levelOneCategoryList: [],
      levelTwoCategoryList: [],
      levelThreeCategoryList: [],
      statusOps: statusOps(1),
      statusMap
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  watch: {
  },
  created () {
    // this.$setBreadcrumb('检查模板')
  },
  methods: {
    create () {
      this.$router.push({
        name: 'qualityChecklistTemplateForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'qualityChecklistTemplateForm',
        query: {
          id: row.id
        }
      })
    },

    isShowEditBtn (row) {
      if (this.userType == '106') {
        return true
      }
      if (this.userType == '102' && row.hierarchy == '1') {
        return true
      }
      if (this.userType == '100') {
        return true
      }
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
