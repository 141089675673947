var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualityChecklistTemplateList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          exportMethod: _vm.exportMethod,
          searchParams: _vm.searchParams,
          isMultiSelect: false,
          headers: _vm.headers,
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: {
                    label: "检查表模板名称",
                    placeholder: "检查表模板名称",
                  },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    type: "rangedatetimer",
                    label: "创建时间",
                    format: "YYYY-MM-DD HH:mm",
                    startTime: _vm.searchParams.createStartTime,
                    endTime: _vm.searchParams.createEndTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "createStartTime",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "createStartTime",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "createEndTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "createEndTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _vm.isShowEditBtn(scope.row)
                  ? _c("v-button", {
                      attrs: { text: "编辑", type: "text", permission: "edit" },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _c("v-button", {
                  attrs: {
                    text: scope.row.status === 0 ? "停用" : "恢复",
                    type: "text",
                    permission: "status",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateStatus(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }